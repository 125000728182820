import React from "react"

import SEO from "../components/common/seo"
import LayoutSecondary from "../components/common/layout-secondary";
import Breadcrumbs from "../components/common/breadcrumbs";
import Subsidy from "../components/subsidies/subsidy";
import { graphql } from 'gatsby'

const FederPage = ({data, pageContext}) => {
    const lang = pageContext.locale.path.default;
    const page = data.allStrapiFondosPublicos.edges[0].node;
    const breadcrumbs = [{text: page.title, active: true}];

    return (
        <LayoutSecondary pageContext={pageContext} cookies={data.cookies}>
            <SEO lang={lang} title={page.seo.title} description={page.seo.meta_description}/>

            <Breadcrumbs breadcrumbs={breadcrumbs}/>

            {/* Fondos públicos ================================================== */}
            <section className="bg-grey pt-8 pt-md-9">
                <div className="container">

                    {/* Heading */}
                    <h1 className="display-4 font-weight-bold spacing">
                        {page.title}
                        <br/><br/>
                    </h1>

                    {data.allStrapiSubsidy.edges.map((document, i) => {
                        return <Subsidy subsidy={document.node} key={i}/>
                    })}

                </div>
                {/* / .container */}
            </section>

        </LayoutSecondary>
    )
}

export default FederPage

export const federPageQuery = graphql
    `query($lang: String) {
        allStrapiFondosPublicos (
            filter: { locale: { eq: $lang } }
        ) {
            edges {
                node {
                    id,
                    seo {
                        title,
                        meta_description
                    }
                    title
                }
            }
        }
        allStrapiSubsidy(
            filter: { locale: { eq: $lang } }
        ){
            edges {
                node {
                    id
                    title
                    name
                    beneficiary
                    duration
                    date_end
                    budget
                    funded_by
                    description
                    logo_1 {
                        name
                        localFile {
                            publicURL
                        }
                    }
                    logo_2 {
                        name
                        localFile {
                            publicURL
                        }
                    }
                    logo_3 {
                        name
                        localFile {
                            publicURL
                        }
                    }
                }
            }
        }
        cookies: markdownRemark(
            frontmatter: {
                lang: {
                eq: $lang
            }
            name: {
                eq: "cookies"
            }
            }) {
                frontmatter {
                cookiesStart
                cookiesEnd
                linkText
            }
        }
    }`;

