import React from "react"
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

const Subsidy = ({subsidy}) => {

    return (
        <div className="row">
            <div className="col-12 col-md-12">

                <h3 className="font-weight-bold spacing text-tecalis">
                    {subsidy.title}
                </h3>
                <br/>

                {/* Texto */}
                <ReactMarkdown children={subsidy.description} rehypePlugins={[rehypeRaw]} className="spacing font-size-sm"/>

                <div className="table-responsive">
                    <table className="table">

                        <tbody>
                        {subsidy.name && <tr>
                            <td className="spacing font-size-sm font-weight-bold">Nombre de la ayuda</td>
                            <td className="spacing font-size-sm">{subsidy.name}</td>
                        </tr>}

                        {subsidy.beneficiary && <tr>
                            <td className="spacing font-size-sm font-weight-bold">Beneficiario</td>
                            <td className="spacing font-size-sm">{subsidy.beneficiary}</td>
                        </tr>}

                        {subsidy.duration && <tr>
                            <td className="spacing font-size-sm font-weight-bold">Duración</td>
                            <td className="spacing font-size-sm">{subsidy.duration}</td>
                        </tr>}

                        {subsidy.date_end && <tr>
                            <td className="spacing font-size-sm font-weight-bold">Finalización</td>
                            <td className="spacing font-size-sm">{subsidy.date_end}</td>
                        </tr>}

                        {subsidy.budget && <tr>
                            <td className="spacing font-size-sm font-weight-bold">Presupuesto total</td>
                            <td className="spacing font-size-sm">{subsidy.budget}</td>
                        </tr>}

                        <tr>
                            <td className="spacing font-size-sm font-weight-bold">Financiado por</td>
                            <td className="spacing font-size-sm">{subsidy.funded_by}

                                <div className="row mt-5">
                                    <div className="col-xl-3">{subsidy.logo_1 &&
                                    <img src={subsidy.logo_1.localFile.publicURL} width="200px"
                                         alt={subsidy.logo_1.name}
                                         className="img-fluid mb-2"/>
                                    }</div>
                                    <div className="col-xl-3">{subsidy.logo_2 &&
                                    <img src={subsidy.logo_2.localFile.publicURL} width="200px"
                                         alt={subsidy.logo_2.name}
                                         className="img-fluid mb-2"/>
                                    }</div>
                                    <div className="col-xl-3">
                                        {subsidy.logo_3 &&
                                        <img src={subsidy.logo_3.localFile.publicURL} width="200px"
                                             alt={subsidy.logo_3.name}
                                             className="img-fluid mb-2"/>
                                        }
                                    </div>
                                </div>
                            </td>

                        </tr>

                        </tbody>
                    </table>
                </div>

                <br/><br/><br/>

            </div>
        </div>
    )
}

export default Subsidy
